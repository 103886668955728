exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-educator-tsx": () => import("./../../../src/pages/educator.tsx" /* webpackChunkName: "component---src-pages-educator-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movement-studies-tsx": () => import("./../../../src/pages/movement-studies.tsx" /* webpackChunkName: "component---src-pages-movement-studies-tsx" */),
  "component---src-pages-performer-tsx": () => import("./../../../src/pages/performer.tsx" /* webpackChunkName: "component---src-pages-performer-tsx" */),
  "component---src-pages-practitioner-tsx": () => import("./../../../src/pages/practitioner.tsx" /* webpackChunkName: "component---src-pages-practitioner-tsx" */),
  "component---src-pages-reflections-tsx": () => import("./../../../src/pages/reflections.tsx" /* webpackChunkName: "component---src-pages-reflections-tsx" */),
  "component---src-pages-researcher-tsx": () => import("./../../../src/pages/researcher.tsx" /* webpackChunkName: "component---src-pages-researcher-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

